.signature-banner-wrap {
    padding: 100px 150px !important;

    .left-content {
        background-color: $bg-color-light;
        color: #828282;
        padding: 80px;
        height: 100%;

        h1 {
            font-weight: 200;
            font-size: 60px;
            color: #383838;
            line-height: 60px;
        }

        p {
            margin-bottom: 40px;
            margin-top: 60px;
            color: #828282;
            font-size: 14px;
            line-height: 24px;
        }
    }
}