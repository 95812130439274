.new__collection__wrapper {
    .product__box {
        text-align: center;
        margin-right: 15px;
        margin-left: 15px;
        margin-top: 60px;

        .product__content {
            padding: 20px 0px;

            .product__title {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: .9px;
            }

            .product__info {
                font-size: 12px;
                color: #000;
            }

            .product__action {
                position: relative;

                .product__price,
                .product__cart {
                    width: 100%;
                    transition: .3s;

                    a {

                        color: #000;
                    }
                }

                .product__cart {
                    opacity: 0;
                }

                .product__cart {
                    position: absolute;
                    transform: translateY(100%);
                    bottom: 0;
                }
            }

            .product__action:hover .product__cart {
                transform: translateY(0%);
                opacity: 1;
            }

            .product__action:hover .product__price {
                transform: translateY(-100%);
                opacity: 0;
            }

        }
    }

}