.admin-sidebar {
    max-width: 60px;
    width: 100%;
    transition: all 0.3s;
    overflow-x: auto;
    overflow-y: auto;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 10;
    border-right: 1px solid #eee;

    &.open {
        max-width: 270px;
    }

}

.sidebar-ttile {
    padding: 12px 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    min-height: 72px;

    h3 {
        font-weight: 500;
        font-size: 20px;
    }

    .sidebar-icons {

        .btn-icon:hover {
            background-color: rgba(108, 117, 125, 0.15);
        }
    }

}

.sidebar_area {
    list-style: none;
    padding: 0;
    position: relative;
    margin: 1rem;


    li a.active {
        background-color: rgba(59, 183, 126, 0.2);
        // border-left: 3px solid #86744e;
        border-radius: 6px;

        .sidebar-icons svg {
            fill: #86744e;
        }

        .sidebar-menu-arrow {

            i {
                transition: 0.3s ease-in-out;
                transform: rotate(90deg);
            }
        }



    }

    li a.active:hover {
        background-color: rgba(59, 183, 126, 0.2) !important;
    }

    .menu-item {
        padding: 12px 20px;
        margin-bottom: 5px;
        font-weight: 800;
        font-size: 13px;
        color: #292f46;
        position: relative;
        line-height: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-between;

        .sidebar-icons {
            display: flex;
            align-items: center;
        }

        .sidebar-menu-arrow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: 11px;
            }
        }

        p {
            margin-left: 10px !important;
        }

    }

    .menu-item:hover {
        -webkit-transition: .2s linear;
        transition: .2s linear;
        background-color: #e9ecef;
        border-radius: 6px;
    }

    li {
        .sub-menu {
            display: none;
        }

        ul {

            li {
                padding: 15px 30px;
                background: #dddddd;
                border-bottom: 1px solid #ededed;
                line-height: 1;

                a {
                    color: #292f46;
                    font-weight: 800;
                    font-size: 13px;
                }
            }
        }
    }





}

.sidebar_area li a.active+.sub-menu {
    display: block;
}

.my_nav_drop {
    border: none !important;

    button {
        padding: 0 !important;
        display: inline-block;
        box-shadow: unset !important;
    }
}

.sub_menu {
    p {
        color: #6c757d;
        padding: 5px 0 5px 15px;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        position: relative;
        margin-left: 5px;
        font-size: 12px;

    }
}