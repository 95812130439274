.product-wrap {
    margin-top: 90px;
    padding-top: 50px;
    background: #f7f7f7;

    .single-product-image {
        .base-image {
            border: 1px solid #efefef;
            padding: 5px;
            border-radius: 4px;
            margin-bottom: 20px;
            width: 440px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .image-gallery-wrap {
            display: flex;


            .addtional-image {
                width: 80px !important;
                margin-right: 10px;
                height: 80px !important;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .certification{
        margin-top: 40px;
        display: flex;
        justify-content: space-around;

        span{
            font-size: 14px;
            font-weight: 600;
        }

        img{
            width: 300px;
        }
    }

    .product-details {
        padding-left: 50px;

        .product-title {
            h2 {
                margin-bottom: 8px;
                font-weight: 500;
            }

            span {
                font-size: 14px;
                display: inline-block;
                font-weight: 300 !important;
                color: #6b6969;
                font-family: "Source Serif Pro", serif;
                margin-bottom: 5px;

                &:nth-of-type(1) {
                    position: relative;
                    margin-right: 30px;

                    &::after {
                        position: absolute;
                        top: 5px;
                        right: -15px;
                        content: "";
                        width: 1px;
                        height: 13px;
                        background: #c0c0c0;
                    }
                }

                &:nth-of-type(2) {
                    a {

                        color: #6b6969;
                    }
                }
            }


            .btn {
                font-size: 14px;
                font-weight: 500;
                padding: 0px;
                margin-bottom: 5px;

                &:nth-of-type(1) {
                    margin-right: 15px;
                }

                i {
                    font-size: 13px;
                    margin-right: 5px;
                }
            }

            p {
                font-weight: 400;
                color: #35a135;
                border-bottom: 1px solid #e2e2e2;
                margin-bottom: 25px;
            }



        }

        .tax{
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .arsapromise {
            clear: both;
            color: #333;
            border-top: 1px solid #e1e1e1;
            border-bottom: 1px solid #e1e1e1;
            text-align: center;
            padding: 10px 0;
            font-weight: 500;
            margin-bottom: 15px;
            width: 600px;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;

                li {

                    display: flex;
                    align-items:center;
                    padding: 0px 14px;

                    span {
                        color: #333;
                        font-size: 12px;
                        line-height: 18px;
                        padding: 0px 5px;
                        text-decoration: none;
                        cursor: pointer;

                        .money-back {
                            // background-position: -3px -44px;
                            width: 18px;
                            height: 16px;

                        }

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            line-height: initial;
                            letter-spacing: 0;
                        }
                    }
                }
                .border-right {
                    border-right: .5px solid lightgrey;
                }

            }

           
        }

        .price-section span {
            font-family: "Source Serif Pro", serif;
            font-size: 30px;
            margin-bottom: 25px;
            display: block;
            font-weight: 500;
        }

        .product-customization {
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            border: 1px solid #e2e2e2;

            p {
                margin-bottom: 10px;
                font-weight: 400;
                font-family: "Source Serif Pro", serif;
                font-size: 15px;
            }

            .customization-details {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .customization-title {
                    min-width: 150px;

                    span {
                        font-family: "Source Serif Pro", serif;
                    }

                }

                .customization-btn {
                    .btn-outline-primary {
                        padding: 5px 12px;
                        border-radius: 5px;
                        border: 1px solid #c2c2c2;
                        background: transparent;
                        margin-right: 10px;
                        font-size: 13px;
                        font-weight: 300;
                        font-family: "Source Serif Pro", serif;
                        color: #6b6969;

                    }

                    .btn-check:checked+.btn-outline-primary {
                        color: #fff;
                        font-weight: 600;
                        background: $bg-color-dark;
                        border: 1px solid $bg-color-dark;
                    }

                }
            }


        }

        .metalCaratAvailibility {
            .customization-btn {
                .btn-outline-primary {
                    padding: 5px 12px;
                    border-radius: 5px;
                    border: 1px solid #c2c2c2;
                    background: transparent;
                    margin-right: 10px;
                    font-size: 13px;
                    font-weight: 300;
                    font-family: "Source Serif Pro", serif;
                    color: #6b6969;

                }

                .btn-check:checked+.btn-outline-primary {
                    color: #fff;
                    font-weight: 600;
                    background: $bg-color-dark;
                    border: 1px solid $bg-color-dark;
                }
            }
        }



        .add-product-cart {
            display: flex;
            margin-bottom: 20px;

            .number-picker {
                display: inline-flex;
                align-items: center;
                margin-right: 20px;

                label {
                    font-weight: 500;
                    font-family: "Source Serif Pro", serif;
                    font-size: 15px;
                }

                .input-group-quantity {
                    position: relative;
                    margin-left: 20px;

                    .input-quantity {
                        width: 100px;
                        padding: 10px 39px 10px 10px;
                        text-align: center;
                        border-color: #ebebeb;
                        border-radius: 2px;
                        font-size: 14px;
                        height: 45px;
                    }

                    .btn-wrapper {
                        position: absolute;
                        top: 0;
                        right: 0;

                        .btn-number {
                            position: absolute;
                            right: 0;
                            width: 30px;
                            padding: 0;
                            line-height: 18px;
                            background: none;
                            border: 1px solid #ebebeb;
                            border-radius: 0;

                        }

                        .btn-plus {
                            top: 0;
                            height: 24px;
                            border-top-right-radius: 2px;
                        }

                        .btn-minus {
                            top: 23px;
                            height: 22px;
                            border-bottom-right-radius: 2px;
                        }
                    }


                }
            }
        }



    }



    .product-summary-wrapper {
        background: #fff;
        border-radius: 8px;
        border: 1px solid #e2e2e2;
        overflow: hidden;
    }

    .product-summry {
        padding: 15px;
    }

    .product-summry p {
        margin-bottom: 10px;
        font-weight: 400 !important;
    }

    .summary-list {
        display: flex;
        justify-content: space-between;
    }

    .summary-list span {
        font-size: 14px;
        font-weight: 300 !important;
        margin: 5px 0px;
        color: #6b6969;
    }

    .notice-area {
        display: flex;
        background: #f9f9f9;
        padding: 2px;
        align-items: center;
    }

    .notice-area p {
        font-size: 12px;
        line-height: 18px;
    }

    .notice-area i {
        padding: 16px;
    }
}
.enlargedImage{
    z-index: 4;
}