.sort-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.87rem;

    .category-title {
        font-size: 1.25rem;
    }
    .sort-selector {
        display: flex;
        align-items: center;
        .sort-title{
            width: 100px;
        } 
    }
}