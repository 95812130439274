.card {
    margin-bottom: 24px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eee;
    border-radius: 5px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    overflow: hidden;

 

    .icontext {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: top;
        -ms-flex-align: top;
        align-items: top;
    }

    .icontext span {
        font-size: 22px;
        font-weight: 600;
        display: block;
    }

    .icontext span {
        font-size: 22px;
        font-weight: 600;
        display: block;
    }

    .icontext .icon {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 15px;

        .bi-currency-exchange::before {
            background: rgb(253 142 28 / 10%);
            padding: 10px;
            border-radius: 50px;
            color: rgb(253 142 28);
        }

        .bi-cart3::before {
            background: rgb(255 0 0 / 10%);
            padding: 10px;
            border-radius: 50px;
            color: rgb(255 0 0);
        }

        .bi-basket::before {
            background: rgb(0 181 23 / 10%);
            padding: 10px;
            border-radius: 50px;
            color: rgb(0 181 23);
        }

        .bi-people::before {
            background: rgb(13 202 240 / 10%);
            padding: 10px;
            border-radius: 50px;
            color: rgb(13 202 240);
        }
    }

    .card-title {
        color: #383e50 !important;
        font-weight: 700;
    }

    .icontext span.text-sm {
        font-size: 12px;
        font-weight: 400;
        color: #6c757d;
    }
}

.card:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-box-shadow: none;
    box-shadow: none;
}

