.search-form{
    display: block;
    position: relative;
    ::placeholder{
        color: $white;
        font-size: 85%;
    }
    .search-input{
        background-color: transparent;
        border: unset;
        border-bottom: 1px solid $white;
        color: $white;
        border-radius: 0;
    }
    .header-search-sm{
        background-color: transparent;
        border: unset;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        color: $white;
    }
}