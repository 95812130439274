.content-main {
    padding: 30px 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f8f9fa;

    .card-login {
        max-width: 350px;
    }
}

.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}

.form-check-input:checked {
    background-color: #86744e;
    border-color: #86744e;
}

.font-sm {
    font-size: 13px;
}

.text-muted {
    color: #adb5bd !important;
}