.order-details-btn {

    .dropdown-menu {
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0;
        font-size: 13px;
        color: #6c757d;
        text-align: left;
        list-style: none;
        background-color: #fff;
        border: 0;
        border-radius: 0.25rem;
        box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
    }

    .dropdown-toggle {
        font-size: 13px;
        line-height: 0.7;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        // border-color: 0.5px solid #c0dbd9;
        border: 1px solid #737881;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0.5rem;
        border-radius: 4px;

        svg {
            fill: #737881;
        }
    }

    .dropdown-toggle:hover,
    .dropdown-toggle:hover svg {
        background-color: #86744e;
        fill: $white;
    }


    .dropdown-toggle::after {
        display: none;
    }

}

.icontext .icon i::before {
    background: rgb(28 253 83 / 10%);
    padding: 10px;
    border-radius: 50px;
    color: rgb(59, 183, 126);
}

.table-responsive{
    .order-image{
        width: 60px;
        height: 60px;
    }
}