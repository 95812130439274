@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@200;300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-size: 16px;
}

body {
    font-family: 'Quicksand', sans-serif !important;
    letter-spacing: 0.2px !important;
    line-height: 1.56rem !important;
    font-weight: 200 !important;
}

a {
    text-decoration: none !important;
    font-size: 0.875rem;
}

ul,
ul>li,
ol,
ol>li {
    list-style: none;
    margin: 0px ;
    padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    margin: 0px;
}

p {
    margin: 0px;
}

// span {
//     font-family: "Raleway", sans-serif;
// }

.arsa-container-fluid {
    padding: 0px 3%;
    margin-left: auto;
    margin-right: auto;
}

.section-padding{
    padding: 60px 0px;
}
.section__title{
    text-align: center;
    font-weight: 400;
    color: #141414;
    font-family: Poppins, sans-serif;
    font-size: 34px;
    text-transform: uppercase;
    margin: 25px 0;
}

.card{
    overflow: visible !important;
}
.link-style-white {
    color: #fff;
    text-decoration: none;
}

.link-style-white:hover {
    color: #fff;
    text-decoration: none;
}

.link-style-black {
    color: #737881;
    text-decoration: none;
}

.link-style-black:hover {
    color: #000;
    text-decoration: none;
}

.primary-button {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 40px;
    color: $white;
    border: none;
    background-color: $bg-color-dark;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
}
.primary-button:hover {
    background-color: $bg-color-extra-dark;
}

.secondary-button {
    background-color: transparent;
    border: 1px solid #c0dbd9;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0.5rem 0.95rem;
    border-radius: 4px;
    transition: 0.3s ease-in-out;

}

.secondary-button:hover {
    background-color:$bg-color-dark ;
    color: $white !important;
}

.main-title {
    color: $text-color-header !important;
    font-weight: 700;
}

.arsa-btn {
    width: 240px;
    height: 50px;
    border-radius: 0px;
}

.custom-btn-white {
    background: transparent;
    border: 1px solid $white;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 11px;
    transition: all 0.3s;
    font-weight: 600;
    text-align: center;

    &:hover {
        background: $white;
        border: 1px solid $white;
        color: $black;
    }
}

.custom-btn-dark {
    background:$bg-color-dark;
    border: 1px solid $bg-color-dark;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 11px;
    transition: all 0.3s;
    font-weight: 600;
    text-align: center;

    &:hover {
        background: $white;
        color: $bg-color-dark;
        border: 1px solid $bg-color-dark;

        i{
                color: $bg-color-dark;
        }
    }

    i {
        font-size: 14px;
        margin-right: 10px;
        color: $white;

        
    }
}

.btn_edit {
    padding: 5px 10px;
    margin-right: 5px;
}

.container-main {
    padding: 30px 3%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f8f9fa;
}

.container-frontend {
    padding: 30px 3%;
    margin-left: auto;
    margin-right: auto;
}


.container-fluid {
    margin: 0 !important;
    padding: 0 !important;

}

.form-check-input[type=checkbox] {
    border-radius: 0px;
    transition: all 0.3s;
}
.accordion-button{
    font-size: 14px;
    font-weight: 500;
}

.form-check-input:focus,
.form-control:focus,
.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #202020;
    background: #f7f7f7;
}

// data table column style
.rdt_TableCol {
    font-weight: 600 !important;
    font-size: 13px !important;
}
.accordion-body{
    padding: 0px;
}
thead, tbody, tfoot, tr, td, th {
    vertical-align: middle;
}
.btn:disabled{
    border: 0px;
    color: #a8a8a8;
}


///Attributes/////
.attribute-values{
    display: flex;
    justify-content: space-between;
    background: #efefef;
    padding: 12px 7px 12px 12px;
    margin-bottom: 5px;
    align-items: center;
    text-transform: capitalize;

    .attribute-action{
        i{
            padding: 9px;
            background: #d2d2d2;
            border-radius: 3px;
            font-size: 15px;
        }
    }
}

input[type=file]:focus, input[type=checkbox]:focus, input[type=radio]:focus {
    outline: 0px auto -webkit-focus-ring-color !important;  
    outline-offset: -2px;
}

.form-check-input:focus {
    border-color: #86744e;
    outline: 0;
    box-shadow: 0;
}

.loader-wrapper{
    height: 500px;
    width: 100%;
    position: relative;
    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(50%);
        width: 30px;
        height: 30px;
        border: 3px solid $bg-color-dark;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
    
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: $bg-color-dark;
}

.nav-pills .nav-link {
    color: $bg-color-dark;
}

.product-details-table tr th,
.product-details-table tr td{
    padding-left:16px;
    padding-right:16px;
    font-size: 13px;
}
.total-product-details-table{
    min-width: 100px;
    border-top-width: 1px;
    border-bottom-width: 0px;
    tr{

    }
    th{
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    }
}