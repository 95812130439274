.cart-wrap {
    padding-top: 100px;
    background: #f7f7f7;

    .cart-title {
        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
        }
    }

    .cart-item {
        table {
            border: 1px solid #dee2e6;

            tr {
                th {
                    font-weight: 500;
                    padding: 7px 30px;
                    border-top: none;
                    background: #f3f3f3;
                    font-size: 13px;
                    text-transform: uppercase;
                    border: 1px solid #dee2e6;
                }

                td {
                    border: 1px solid #dee2e6;
                    padding-left: 30px;
                    background: #fff;

                    img {
                        width: 60px;
                        height: 60px;
                    }

                    span {
                        display: block;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        cursor: pointer;
                        color: #898989;

                        &:nth-of-type(2) {
                            font-size: 12px !important;
                        }
                    }

                    .number-picker {
                        position: relative;
                        width: 57px;

                        .btn-number {
                            position: absolute;
                            padding: 0;
                            top: 50%;
                            background: #ffffff;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            font-size: 12px;
                        }

                        .btn-minus {
                            left: 0;
                        }

                        .btn-plus {
                            right: 0;
                        }

                        .input-quantity {
                            font-size: 14px;
                            text-align: center;
                            border: none;

                        }
                    }
                }
            }
        }
    }


}