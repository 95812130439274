.order-summary-wrap {
    width: 380px;
    min-width: 380px;
    margin-left: auto;
    font-family: "Source Serif Pro", serif;

    .order-summary {
        padding: 30px;
        background: #fff;
        border-radius: 2px;

        .order-summary-top {
            padding-bottom: 10px;
            border-bottom: 1px solid #e5e5e5;
        }

        .order-summary-middle {
            padding: 25px 0 8px;

            .order-summary-list li {
                display: flex;
                justify-content: space-between;
            }

            .shipping-methods {
                margin: 21px 0 -6px;

                h6 {
                    margin-bottom: 16px;
                }

                .form-check {
                    display: flex;
                    justify-content: space-between;

                    .fair-type{
                        input{
                            margin-right:8px;
                        }
                    }

                }
            }
            .order-summary-total {
                font-size: 18px;
                font-weight: 500;
                display: -webkit-box;
                display: flex;
                margin-top: 21px;
                -webkit-box-pack: justify;
                justify-content: space-between;
                padding: 14px 0;
                border-top: 1px solid #ebebeb;

               
            }
        }

        .order-summary-bottom{
            button{
                width: 100%;
                letter-spacing: 2px;
            }
        }
    }
}