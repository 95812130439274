.product-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;

    padding: 20px;
    

    .product-inner {
        width: 100%;
        border: 1px solid #efefef;
        background: #f9f9f9e0;
        border-radius: 8px;
        box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
        overflow: hidden;
       

        .product-thumbnail {
            width: 100%;
            height: 310px;
            position: relative;
            overflow: hidden;
            background: #fff;

            .product-label {
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1;

                span {
                    background-color: #f2807f;
                    width: 50px;
                    color: #fff;
                    display: block;
                    z-index: 1;
                    left: 0;
                    font-size: 14px;
                    padding: 5px 0;
                    line-height: 14px;
                    font-weight: 500;
                    top: 0;
                    text-align: center;
                    text-transform: lowercase;
                }
            }

            .product-action {
                background-color: #fff;
                text-align: center;
                width: 100%;
                position: absolute;
                bottom: 0;
                opacity: 0;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                transform: translateY(100%);

                .action-btn {
                    display: inline-block;
                    font-size: 16px;
                    position: relative;
                    cursor: pointer;


                    .action-btn-content {
                        display: inline-block;
                        width: 55px;
                        height: 50px;
                        line-height: 50px;
                        background-color: #fff;
                        color: #333333;

                        &:hover {
                            span {
                                opacity: 1;
                                visibility: visible;
                                bottom: 112%;
                            }
                        }

                        span {
                            padding: 2px 5px;
                            position: absolute;
                            text-transform: none;
                            display: inline-block;
                            min-width: 80px;
                            opacity: 0;
                            text-align: center;
                            visibility: hidden;
                            bottom: 100%;
                            left: 50%;
                            background: #555 !important;
                            color: #fff !important;
                            font-size: 12px !important;
                            line-height: 20px !important;
                            font-weight: normal;
                            -webkit-transform: translate(-50%, 0);
                            -ms-transform: translate(-50%, 0);
                            -o-transform: translate(-50%, 0);
                            transform: translate(-50%, 0);
                            transition: 0.5s;

                            &:after {
                                width: 0;
                                height: 0;
                                position: absolute;
                                bottom: -6px;
                                left: 50%;
                                content: "";
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 7px solid #555;
                                -webkit-transform: translate(-50%, 0);
                                -ms-transform: translate(-50%, 0);
                                -o-transform: translate(-50%, 0);
                                transform: translate(-50%, 0);
                            }
                        }
                    }

                    &:nth-child(1):after,
                    &:nth-child(2):after,
                    &:nth-child(3):after {
                        background-color: #ededed;
                        content: "";
                        width: 1px;
                        height: 20px;
                        right: 0;
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
             
            }
          
            .product-image {
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    transform: scale(0.7);
                }

            }
            
            &:hover {
                .product-action {
                    opacity: 1;
                    transform: translateY(0%);
                }
            }
        }
        

        .product-info {
            padding: 10px;
            // background: #f6f6f6;


            .product-price {
                span {

                    font-family: "Source Serif Pro", serif !important;
                    margin-top: 10px;
                    font-size: 16px;
                    color: #383838;
                    font-weight: 400;

                    &:nth-child(2) {
                        font-size: 14px;
                        margin-left: 14px;
                        text-decoration: line-through;
                        font-weight: 100;
                    }
                }

            }

            .product-name {
                span {
                    font-size: 14px;
                    font-weight: 600;
                    font-family: "Source Serif Pro", serif !important;
                }
            }
            .product-rating{
                i{

                    font-size: 12px;
                    color: #ffc107;
                }
            }
        }
      
      
    }
}