

.product-card-wrapper{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .product__image {
        position: relative;
        overflow: hidden;
        .product__media_hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity .3s ease;
    
            img {
                transform: scale(1.06) translateX(0);
                transition: transform .6s cubic-bezier(.61, 1, .88, 1);
            }
        }
    
        .product__action {
            width: 100%;
            height: 3.75rem;
            background-color: #ffffffD9 !important;
            color: #fff;
            text-align: center;
            line-height: 3.75rem;
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition: all 0.5s;
            transform: translateY(100%);
    
            a {
                font-size: 0.75rem;
                color: #000000;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.9px;
                position: relative;
            }
    
            a::after {
                content: '';
                position: absolute;
                left: 0px;
                top: 100%;
                background-color: #000;
                width: 0px;
                height: 1px;
                transition: .3s;
    
            }
    
            a:hover::after {
                width: 100%;
            }
    
        }
    
    }
    
    .product__image:hover .product__media_hover {
        opacity: 1;
    
        img {
            transform: scale(1) translateX(0);
            transition: transform .6s cubic-bezier(.61, 1, .88, 1);
        }
    
    }
    
    .products__content {
        padding: 1.25rem 0.8rem;
        cursor: pointer;
    
        .title__holder {
            display: flex;
            justify-content: space-between;
            &:hover{
                color: $bg-color-extra-dark;
            }
            .product__title {
                font-size: 0.93rem;
                text-transform: uppercase;
                font-weight: 600;
            }
    
            .products__actions {
                i {
                    font-size: 1rem;
                }
    
                i:last-child {
                    margin-left: 18px;
                }
            }
        }
    
        .product__info {
            text-transform: capitalize;
            font-size: 0.8rem;
        }
    
        .products__price {
            font-weight: 500;
        }
    }
    
    .product__image:hover .product__action {
        transform: translateY(0%);
        opacity: 1;
    }
}