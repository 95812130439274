
.toggle-button-cover {
    display: table-cell;
    position: relative;
    height: 40px;
    box-sizing: border-box;
}

.button-cover {}

.button-cover,
.knobs,
.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button {
    position: relative;
    top: 50%;
    width: 50px;
    height: 26px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r,
.button.r .layer {
    border-radius: 100px;
}

.button.b2 {
    border-radius: 20px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #e6e6e6;
    transition: 0.3s ease all;
    z-index: 1;
}

#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs i {
    position: absolute;
    top: 4px;
    width: 20px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
}

#button-10 .knobs:before {
    content: '';
    left: 4px;
    background-color: #383838;
    border-radius: 20px;
}

#button-10 .knobs:after {
    content: '$';
    right: 4px;
    color: #4e4e4e;
    top: 0px;
    border-radius: 20px;
}

#button-10 .knobs i {
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
    top: 0px;
}

#button-10 .checkbox:checked+.knobs i {
    color: #4e4e4e;
}

#button-10 .checkbox:checked+.knobs:before {
    left: 26px;
    background-color: #383838;
}

#button-10 .checkbox:checked+.knobs:after {
    color: #fff;
}

#button-10 .checkbox:checked~.layer {
    background-color: #e6e6e6;
}