

.filter-wrap {
    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        
        span {}

        a {}
    }

    .filterRange-picker {
        margin-bottom: 20px;

        h6 {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 10px;
        }

        .price-input {
            display: flex;
            margin-bottom: 10px;

            .wrapper-price {
                width: 150px;

                &:first-child {
                    margin-right: 10px;
                }

                input {
                    border-radius: 0px;
                    border: 1px solid #e6e6e6 !important;
                    height: 40px;
                    position: relative;
                }

                span {
                    border: 1px solid #e6e6e6;
                    border-radius: 0px;
                    background: transparent;
                }
            }
        }
    }

    .filter-gender {
        margin-bottom: 20px;

        h6 {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 10px;
            color: #86744e;
        }


    }
}

.product-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0px 20px;

    .category-title {
        span {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .pagination-view-option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i,
        select {
            margin-left: 15px;
        }

        i {
            font-size: 16px;
        }

        h5 {
            width: 50px
        }
    }
}