.massonary-banner-wrap {
    background-color: #f8f8f8;
    
    .massonary-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(5, 1fr);

    .massonary-banner {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        cursor: pointer;

        &:nth-of-type(3) {
            grid-column: 3;
            grid-row: 1 / span 2;
        }

        &:nth-of-type(4) {
            grid-column: 4 / span 2;
        }

        &:nth-of-type(5) {
            grid-column: 1 / span 2;
        }

        :hover {
            transform: scale(1.1);
        }
        //  &::before{
        //     position: absolute;
        //     bottom: 0px;
        //     content: "";
        //     background: linear-gradient(180deg, rgb(33 31 55 / 0%) 0%, rgba(0, 0, 0, 0) 67%, rgb(0 0 0) 100%);
        //     width: 100%;
        //     height: 100%;
        // }

        img {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
            transition: all .3s linear;

        }

        span {
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%);
            color: #fff;
            font-size: 15px;
            font-weight: 400;
        }
    }
}

}