.section-title{
    text-align: center;
    padding: 50px;

    h2{
        font-weight: 400;
    }
    p{
        font-size: 13px;
        margin: 5px;
    }
    hr{
        width: 150px;
        margin: auto;
        color: #000;
    }
}