.instagram-wrap {
    .instatgram-area-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 0px;

        span {
            font-size: 45px;
            font-weight: 200;
        }
    }

    .photoCollab {

        .content {
            position: relative;
            max-width: 400px;
            margin: auto;
            overflow: hidden;

            .content-overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0px;
                top: 0px;
                bottom: 0px;
                right: 0px;
                opacity: 0;
                background: rgba(0, 0, 0, 0.7);
                transition: all 0.4s ease-in-out 0s;
            }

            .content-details {
                position: absolute;
                text-align: center;
                padding-left: 1em;
                padding-right: 1em;
                width: 100%;
                top: 50%;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: all 0.3s ease-in-out 0s;

                .overlayIcon {

                    span {
                        color: $white;
                        margin-right: 8px;
                    }
                }
            }

            &:hover {
                .content-details {
                    top: 50%;
                    left: 50%;
                    opacity: 1;
                    display: block;
                }

                .content-overlay {
                    opacity: 1;
                }
            }
        }
    }
}