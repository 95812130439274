.checkout-wrap {
    padding-top: 150px;
    padding-bottom: 100px;

    .steps-wrap {
        margin-bottom: 40px;

        .step-tabs {
            display: flex;
            justify-content: center;


            .step-tab {
                font-size: 18px;
                font-weight: 500;
                height: 60px;
                width: 350px;
                display: flex;
                margin: 0px;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                color: #191919;
                text-align: center;
                background: #ffffff;
                border: 1px solid #ebebeb;
                border-radius: 2px;
                white-space: nowrap;
                cursor: default;



                .step-tab-link {
                    display: flex;
                    width: 100%;
                    color: #191919;
                    justify-content: center;
                    align-self: stretch;
                    align-items: center;

                    .step-tab-text {
                        position: relative;
                        font-family: "Source Serif Pro", serif !important;
                        font-weight: 100;

                        .bg-text {
                            font-size: 45px;
                            position: absolute;
                            top: 50%;
                            right: -35px;
                            width: 50px;
                            color: #191919;
                            opacity: 0.15;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            font-family: "Source Serif Pro", serif !important;
                        }
                    }
                }

                .step-tab-text {
                    position: relative;
                    font-family: "Source Serif Pro", serif;
                    font-weight: 100;

                    .bg-text {
                        font-size: 45px;
                        position: absolute;
                        top: 50%;
                        right: -35px;
                        width: 50px;
                        color: #191919;
                        opacity: 0.15;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        font-family: "Source Serif Pro", serif !important;
                    }
                }

            }

            .active {
                color: #ffffff;
                background: #000;
                background: #000;
                border-color: #000;

                .step-tab-text .bg-text {
                    color: #fff;
                    opacity: 0.30;
                }
            }
        }
    }

    .checkout {
        display: flex;

        .checkout-left {
            flex: 1;
            .billing-details {
                h4 {
                    margin-bottom: 25px;
                    padding-bottom: 13px;
                    border-bottom: 1px solid #e5e5e5;
                    position: relative;
                    font-weight: 500;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        width: 50px;
                        border-bottom: 2px solid #0068e1;
                        border-bottom: 2px solid #000;
                    }
                }
            }

            // .saved-address .form-group {
            //     .form-radio {
            //         margin-bottom: 9px;

            //         input {
            //             position: absolute;
            //             left: 0;
            //             margin: 0;
            //             display: none;

            //         }

            //         input:checked+label::after {
            //             opacity: 1;
            //             -webkit-transform: scale(1);
            //             transform: scale(1);
            //         }

            //         label {
            //             position: relative;
            //             padding-left: 28px;
            //             cursor: pointer;
            //             display: inline-block;
            //             text-align: left;
            //             font-weight: 400;
            //             color: #6e6e6e;

            //             &::before {
            //                 content: "";
            //                 position: absolute;
            //                 left: 0;
            //                 top: 2px;
            //                 width: 17px;
            //                 height: 17px;
            //                 border: 1px solid #e1e1e1;
            //                 border-radius: 50%;
            //                 -webkit-transition: 150ms ease-in-out;
            //                 transition: 150ms ease-in-out;
            //             }

            //             span {
            //                 display: block;
            //                 color: #191919;
            //                 font-family: "Source Serif Pro", serif;
            //                 font-size: 15px;
            //                 font-weight: 300;

            //                 &:nth-of-type(1) {
            //                     font-weight: 400;
            //                     text-transform: capitalize;
            //                 }
            //             }
            //         }


            //     }

            // }

            .saved-address .form-check {
                span {
                    display: block;
                    color: #191919;
                    font-family: "Source Serif Pro", serif;
                    font-size: 15px;
                    font-weight: 300;

                    &:nth-of-type(1) {
                        font-weight: 400;
                        text-transform: capitalize;
                    }
                }
                

            }

            .add-new-address-wrap {
                button {
                    font-weight: 500;
                    margin-bottom: 10px;
                    padding: 5px 0;
                }
            }

            .shipping-details {
                margin-bottom: 10px;

                .form-check {
                    display: flex;
                    align-items: center;
                    padding: 0px;

                    label {
                        position: relative;
                        padding-left: 10px;
                        cursor: pointer;
                        display: inline-block;
                        text-align: left;
                    }
                }
            }

            .order-note-wrap {
                label {

                    font-weight: 500;
                    margin-bottom: 9px;
                }
            }
        }

        .checkout-right {
            width: 395px;
            min-width: 395px;
            padding: 0 15px;

            h4 {
                margin-bottom: 13px;
                position: relative;

                &::after {
                    content: "";
                    height: 2px;
                    width: 100px;
                    position: absolute;
                    background-color: var(--color-primary);
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }

            .payment-method-form {
                padding: 28px 30px 5px;
                border: 1px solid #ebebeb;
                border-radius: 2px;

                .form-group {
                    .form-radio {
                            margin-bottom: 10px;
                            
                        label{
                            position: relative;
                            padding-left: 15px;
                            cursor: pointer;
                            display: inline-block;
                            text-align: left;

                        }
                        .helper-text {
                            font-size: 13px;
                            display: block;
                            margin: -4px 0 0 28px;
                            color: #6e6e6e;
                        }
                    }
                }
            }
        }
    }
}

.form-radio input[type=radio]:checked+label:after,
.form-radio input[type=radio]:not(:checked)+label:after {
    position: absolute;
    content: "";
    top: 7px;
    left: 5px;
    height: 7px;
    width: 7px;
    background: #000;
    border-radius: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
}