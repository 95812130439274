// NAVBAR 
@media screen and (max-width: 1390px) {
    .nav-toggler {
        display: block;
    }

    .header nav {
        justify-content: space-between;

        .nav-left-content,
        .nav-middle-content {
            display: none;
        }

        .nav-right-content .res-search {
            display: block;
        }
    }
}