.nav-toggler div{
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: darkgray;
}

.nav-toggler{
    cursor: pointer;
    display: none;
}
.res-nav-menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background: $bg-color-light;
    border-right:solid 1px #f9f6f4  ;
    transform: translateX(-100%);
    transition: 0.5s ease-in;
}
.res-nav-menu-active{
    transform: translateX(0%);
}
.overlay{
    position: absolute;
                height: 100%;
                width: 100%;
                left: 0px;
                top: 0px;
                bottom: 0px;
                right: 0px;
                opacity: 0;
                background: rgba(0, 0, 0, 0.7);
                transition: all 0.4s ease-in-out 0s;
}

.res-nav-menu{
    overflow-y: auto;

    ul>li{
        transition: all 0.3s;
    }

    .nav__list{
        text-transform: uppercase;
        color: #141414;
        font-weight: 500;
        
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border-bottom: solid 1px #e5e5e5;
        justify-content: space-between;
        transition: all 0.3s;
        
      
    }
        i{
            font-size: 13px;
            transition: 0.3s;
        }
     
    
}


