.auth-register {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.auth-wrapper {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
			0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 48rem;
		max-width: 100%;
		min-height: 30rem;

		form {
			background-color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 0 50px;
			height: 100%;
			text-align: center;

			input {
				background-color: #eee;
				border: none;
				padding: 12px 15px;
				margin: 8px 0;
				width: 100%;

			}

			a {
				color: #333;
				font-size: 14px;
				text-decoration: none;
				margin: 15px 0;
			}

			.forgot-password{
				color: $bg-color-dark;
			}

			.social-container {
				margin: 20px 0;
			}

			.social-container a {
				border: 1px solid $text-color-header;
				border-radius: 50%;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: 0 5px;
				height: 40px;
				width: 40px;
				transition: .3s ease-in-out;
				&:hover{
					border: 1px solid $bg-color-dark;
				}
				&:hover i{
					color: $bg-color-dark;
					transition: .2s ease-in-out;
				}
			}
			.primary-button{
				text-transform: uppercase;
			}
		}

		.auth-container {
			position: absolute;
			top: 0;
			height: 100%;
			transition: all 0.6s ease-in-out;
		}

		.sign-in-container {
			left: 0;
			width: 50%;
			z-index: 2;
		}

		.sign-up-container {
			left: 0;
			width: 50%;
			opacity: 0;
			z-index: 1;
		}

		.auth-overlay-container {
			position: absolute;
			top: 0;
			left: 50%;
			width: 50%;
			height: 100%;
			overflow: hidden;
			transition: transform 0.6s ease-in-out;
			z-index: 100;

			.auth-overlay {
				background: $bg-color-dark;
				background: -webkit-linear-gradient(to right, $bg-color-extra-dark, $bg-color-dark);
				background: linear-gradient(to right, $bg-color-extra-dark, $bg-color-dark);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: 0 0;
				color: #FFFFFF;
				position: relative;
				left: -100%;
				height: 100%;
				width: 200%;
				transform: translateX(0);
				transition: transform 0.6s ease-in-out;

				.auth-overlay-panel {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 0 40px;
					text-align: center;
					top: 0;
					height: 100%;
					width: 50%;
					transform: translateX(0);
					transition: transform 0.6s ease-in-out;

					p{
						margin: 10px 0px;
						text-transform: capitalize;
					}

					.secondary-button{
						background: #fff;
						text-transform: uppercase;
					}
					.secondary-button:hover{
						text-transform: uppercase;
						color: #000 !important;
					}
					
				}


			}
		}


	}

	.auth-wrapper.right-panel-active .auth-overlay-container {
		transform: translateX(-100%);
	}

	.auth-wrapper.right-panel-active .auth-overlay {
		transform: translateX(50%);
	}

	.auth-wrapper.right-panel-active .auth-overlay-left {
		transform: translateX(0);
	}

	.auth-overlay-right {
		right: 0;
		transform: translateX(0);
	}

	.auth-wrapper.right-panel-active .auth-overlay-right {
		transform: translateX(20%);
	}

	.auth-wrapper.right-panel-active .sign-up-container {
		transform: translateX(100%);
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}

	.auth-wrapper.right-panel-active .sign-in-container {
		transform: translateX(100%);
	}

	@keyframes show {

		0%,
		49.99% {
			opacity: 0;
			z-index: 1;
		}

		50%,
		100% {
			opacity: 1;
			z-index: 5;
		}
	}
}