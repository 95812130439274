.headCells {
    font-size: 20px;
    font-weight: 600;
    padding: 0 5px;
    justify-content: center;
    background: FFA500;
}

.searchbar {
    background-color: #f4f5f9;
    border: 2px solid #f4f5f9;
    font-size: 13px;
    box-shadow: none;
    padding-left: 20px;
    color: #4f5d77;
    width: 35%;
    border-radius: 4px;
    height: 41px;
    margin-bottom: 20px;
}

.searchbar:focus {
    outline: none;
}

.product-name {
    color: #495057;
    font-weight: 700;
    font-size: 14px;
}

.edit-btn {
    padding: 6px 20px;
}