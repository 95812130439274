.card-top {
    padding: 1.3rem 0rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(222, 226, 230, 0.7);

}

.create-product,
.form-select {
    background-color: #f4f5f9 !important;
    border: 2px solid #f4f5f9 !important;
    font-size: 13px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #4f5d77 !important;
    width: 100% !important;
    border-radius: 4px !important;
}

.create-product:focus,
.form-select:focus {
    background: transparent;
    border-color: #f4f5f9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-upload img {
    max-width: 100px;
    margin-bottom: 20px;
}

.input-upload .image-upload-icon{
    border: 2px solid #e8e8e8;
    width: 130px;
    margin: 0 auto;
    margin-bottom: 20px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;

    i{
        font-size: 50px;
        color: #e8e8e8;
    }
}

.form-control:focus,
.form-select:focus {

    background: transparent !important;
    border-color: #f4f5f9 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.attribute-row{
    display: flex;
    border: 1px solid #e7ebed !important;
    padding: 5px;
    
    select{
        width: 200px !important;
        background: none !important;
        border-radius: 0px !important;
        border: 2px solid #f4f5f9 !important ;
        margin-right: 5px;
    }
    

}

.searchWrapper{
    border-radius: 0px !important;
    border: 2px solid #f4f5f9 !important ;
    
    .chip{
        background: #86744e;
    }

}
.highlightOption{
    background: #86744e !important;
}
.multiSelectContainer li:hover{
    background: #86744e !important;

}
.optionListContainer{
    transition: 0.3s !important;
    top: 65px !important;
}
.displayNone{
}
.displayBlock{
    top: 50px !important;
}