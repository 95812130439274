//bootstrap
@import './../../node_modules/bootstrap/scss/bootstrap.scss';
@import './../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';

// global SCSS
@import './global/variables';
@import './global/reset.scss';


// ALl LAYOUT CSS
@import 'layouts/layouts';
@import 'layouts/sidebar';
@import 'layouts/header';
@import 'layouts/footer';

//Plugins
@import "./../../node_modules/slick-carousel/slick/slick.css";
@import "./../../node_modules/slick-carousel/slick/slick-theme.css";

// ALl components CSS
@import 'components/navbarToggler';
@import 'components/searchInput';
@import 'components/card';
@import 'components/formdata';
@import 'components/addproduct';
@import 'components/herobanner';
@import 'components/signatureBanner';
@import 'components/massonaryBanner';
@import 'components/sectionTitle';
@import 'components/instagram';
@import 'components/productSlider';
@import 'components/products';
@import 'components/productInner';
@import 'components/dashboardlogin';
@import 'components/login';
@import 'components/singleproduct';
@import 'components/cart';
@import 'components/orderSummary';
@import 'components/checkout';
@import 'components/order';
@import 'components/addattribute';
@import 'components/priceSetting';
@import 'components/account';
@import 'components/frontend';
@import './global/responsive';

//All Frontend Common Components
@import './components/Frontend/Common/breadcrumb';
@import './components/Frontend/Common/productList';
@import './components/Frontend/Common/productsFilterSidebar';
@import './components/Frontend/Common/shortOptions';
@import './components/Frontend/Common/productCard';
@import './components/Frontend/Common/filteredByPrice';
@import './components/Frontend/Common/priceSwitcher';


//All Frontend Page Components
@import './components/Frontend/Pages/produtcs';
@import './components/Frontend/Pages/auth';
@import './components/Frontend/Pages/Acoount//wishlist';


.p-tree .p-treenode-children {
    padding: 0 0 0 1rem !important;
}