.products-wrap {

    .category-list-wrap {
        margin-bottom: 30px;
        margin-top: 30px;

        .category-title {
            margin-bottom: 20px;

            h4 {
                font-weight: 400;
                margin-bottom: 10px;
                color: #86744e;
            }

            hr {
                position: relative;
                margin: 0px;
            }
        }

        .category-list {
            ul {
                li {
                    a {
                        display: flex;
                        align-items: center;

                        .list-down-arrow {
                            width: 20px;

                            i {
                                color: $dark;
                            }
                        }

                        span {
                            font-family: "Source Serif Pro", serif;
                            color: $dark;
                        }
                    }
                }
            }
        }
    }

    .filter-wrap {
        margin-top: 30px;
        .filter-title {
            margin-bottom: 20px;
            color: #86744e;

            h4 {
                font-weight: 400;
                margin-bottom: 10px;
                font-size: 20px;
            }

            hr {
                position: relative;
                margin: 0px;
            }
        }

        .filterRange-picker {
            margin-bottom: 20px;

            h6 {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 10px;
            }

            .price-input {
                display: flex;
                margin-bottom: 10px;

                .wrapper-price {
                    width: 150px;

                    &:first-child {
                        margin-right: 10px;
                    }

                    input {
                        border-radius: 0px;
                        border: 1px solid #e6e6e6 !important;
                        height: 40px;
                        position: relative;
                    }

                    span {
                        border: 1px solid #e6e6e6;
                        border-radius: 0px;
                        background: transparent;
                    }
                }
            }
        }

        .filter-gender {
            margin-bottom: 20px;

            h6 {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 10px;
                color: #86744e;
            }

            
        }
    }
    .product-pagination{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 0px 20px;

        .category-title{
            span{
                font-size: 20px;
                font-weight: 500;
            }
        }
        .pagination-view-option{
            display: flex;
            justify-content: space-between;
            align-items: center;

            i,select{
                margin-left: 15px;
            }
            i{
                font-size: 16px;
            }
            h5{
                width:50px
            }
        }
    }
}