.admin-header {
    padding-left: 3%;
    padding-right: 3%;
    min-height: 72px;
    background-color: #fff;
    -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    border-bottom: 1px solid #eee;

    .header-left-area {
        font-weight: 600;
    }

    .dropdown-toggle {
        background-color: rgb(255 0 0 / 0%) !important;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;

        svg {
            fill: #737881;
        }

        p {
            font-size: 14px;
            font-weight: 600;
        }
    }


    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown-menu {
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0;
        font-size: 13px;
        color: #6c757d;
        text-align: left;
        list-style: none;
        background-color: #fff;
        border: 0;
        border-radius: 0.25rem;
        -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
        box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
    }
}

// .front-header-wrap {
//     width: 100%;
//     position: absolute;
//     z-index: 1;
//     top: 0px;
//     background: #383838;

//     .front-header {
//         max-height: 100px;
//         height: 100%;
//         width: 100%;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         padding: 15px 0;

//         .header-menu,
//         .header-search,
//         .header-logo {
//             flex: 0 0 auto;
//             width: 33.33333333%;
//         }

//         .header-search {
//             .search-form {
//                 max-width: 200px;
//             }
//         }

//         .header-logo {
//             height: inherit;
//             text-align: center;

//             img {
//                 max-height: 70px;
//             }
//         }

//         .header-menu {
//             display: flex;
//             align-items: center;
//             justify-content: flex-end;

//             .currency-changer{
//                 width: 90px !important;
//                 background-color: transparent !important;
//                 border: none !important;
//                 color: #fff !important;            }
//             .header-btn-icon {
//                 background-color: transparent;
//                 border: unset;
//                 color: $white;
//                 padding: 0px 10px;
//                 margin-left: 20px;
//                 position: relative;

//                 span {
//                     position: absolute;
//                     top: -5px;
//                     right: -5px;
//                     height: 20px;
//                     width: 20px;
//                     line-height: 20px;
//                     text-align: center;
//                     display: inline-block;
//                     background-color: $white;
//                     color: $dark;
//                     border-radius: 50%;
//                 }

//                 i {
//                     font-size: 120%;
//                 }

//                 &:last-child {
//                     i {
//                         font-size: 45px;
//                     }
//                 }

//                 .intro {
//                     display: block;
//                     font-size: 80%;
//                     text-transform: uppercase;
//                 }
//             }


//             .hamburger-menu {
//                 position: relative;

//                 .toggler {
//                     z-index: 3;
//                     height: 50px;
//                     width: 60px;
//                     position: absolute;
//                     top: -25px;
//                     right: -70px;
//                     cursor: pointer;
//                     opacity: 0;

//                     &:checked+.hamburger {
//                         >div {
//                             background: transparent;

//                             &::before {
//                                 top: 0;
//                                 transform: rotate(45deg);
//                             }

//                             &::after {
//                                 top: 0;
//                                 transform: rotate(135deg);
//                             }
//                         }
//                     }

//                     &:checked~.menu-wrap {
//                         width: 580px;

//                         .menu {
//                             opacity: 1;
//                         }
//                     }
//                 }

//                 .hamburger {
//                     position: absolute;
//                     top: -20px;
//                     right: -70px;
//                     height: 40px;
//                     width: 60px;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     z-index: 2;

//                     div {
//                         position: relative;
//                         top: 0;
//                         left: 0;
//                         background: white;
//                         height: 1px;
//                         width: 60%;
//                         transition: all 0.4s ease;


//                         &:before,
//                         &:after {
//                             content: '';
//                             position: absolute;
//                             top: -13px;
//                             background: white;
//                             width: 100%;
//                             height: 1px;
//                             transition: all 0.4s ease;
//                             z-index: 1;
//                             left: 0;
//                         }

//                         &:after {
//                             top: 13px;
//                         }
//                     }
//                 }

//                 .menu-wrap {
//                     width: 0px;
//                     top: 20px;
//                     right: 20px;
//                     z-index: 1;
//                     position: fixed !important;
//                     height: 100vh !important;
//                     transition: all 0.5s ease 0s;
//                     background: rgb(56, 56, 56);
//                     overflow: hidden;

//                     .menu {
//                         text-align: center;
//                         padding: 60px;
//                         opacity: 0;
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: space-between;
//                         height: 100%;


//                         .menu-top,
//                         .menu-bottom {
//                             margin: 0 auto;

//                             img {
//                                 margin-bottom: 20px;
//                             }

//                             span {
//                                 font-size: 25px;
//                                 text-transform: uppercase;
//                                 color: $white;
//                                 display: block;
//                                 font-weight: 100;
//                             }

//                             hr {
//                                 width: 120px;
//                                 height: 1px;
//                                 margin: 15px auto;
//                                 color: rgb(225, 225, 225);
//                             }

//                             .menu-list {
//                                 ul {
//                                     li {
//                                         a {
//                                             display: flex;
//                                             align-items: center;
//                                             justify-content: center;

//                                             i {
//                                                 color: rgb(154, 154, 154);
//                                             }

//                                             span {

//                                                 display: block;
//                                                 color: rgb(154, 154, 154);
//                                                 cursor: pointer;
//                                                 font-size: 18px;
//                                                 padding: 5px 10px;
//                                                 text-decoration: none;
//                                                 transition: all 0.2s linear 0s;
//                                             }
//                                         }


//                                     }

//                                     ul {
//                                         display: none;
//                                     }
//                                 }
//                             }
//                         }
//                         .menu-top{

//                         }
//                         .menu-bottom {
//                             p {
//                                 color: rgb(154, 154, 154);
//                                 font-size: 14px;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;

    .logo-wrapper {
        height: 7.19rem;
        text-align: center;
        border-bottom: 1px solid #dcdcdc;

        img {
            width: 9.37rem;
        }
    }

    .sticky {
        background: #fff;
        position: fixed;
        top: -100px;
        left: 0;
        width: 100%;
        -webkit-transition: -webkit-transform .5s;
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-box-shadow: 0px 5px 15px rgb(15 36 84 / 5%);
        box-shadow: 0px 5px 15px rgb(15 36 84 / 5%);
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5rem;


        .nav-left-content {
            width: 300px;
            position: relative;

            img {
                width: 100px;
            }

            .search {
                position: relative;

                i {
                    position: absolute;
                    top: 50%;
                    transform: translate(0%, -50%);
                    left: 12px;
                    cursor: pointer;
                }

                i:hover {
                    color: #86744e;
                }

                .search-box {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                }

                .input-search {
                    height: 50px;
                    width: 50px;
                    border-style: none;
                    padding: 10px;
                    font-size: 18px;
                    letter-spacing: 2px;
                    outline: none;
                    border-radius: 25px;
                    transition: all .5s ease-in-out;
                    background-color: transparent;
                    padding-right: 40px;
                    color: #000;
                }

                .input-search::placeholder {
                    color: rgba(255, 255, 255, .5);
                    font-size: 18px;
                    letter-spacing: 2px;
                    font-weight: 100;
                }

                .btn-search {
                    width: 50px;
                    height: 50px;
                    border-style: none;
                    font-size: 20px;
                    font-weight: bold;
                    outline: none;
                    cursor: pointer;
                    border-radius: 50%;
                    position: absolute;
                    right: 0px;
                    color: #000;
                    background-color: transparent;
                    pointer-events: painted;
                }

                .btn-search:focus~.input-search {
                    width: 18.75rem;
                    border-radius: 0px;
                    // background-color: #86744e;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
                }

                .btn-search:focus i {
                    // color: #fff;
                }

                .input-search:focus {
                    width: 18.75rem;
                    border-radius: 0px;
                    // background-color: #86744e;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
                }
            }
        }

        .nav-middle-content {
            width: 60%;
            text-align: center;
            position: relative;

            ul>li {
                display: inline-flex;

                .nav__list {
                    text-transform: uppercase;
                    color: #141414;
                    font-weight: 500;
                    transition: all .3s;
                    display: flex;
                    align-items: center;
                    padding: 0px 0.5rem;

                    span {
                        line-height: 5rem;

                    }

                    i {
                        font-size: 13px;
                        transition: .3s;
                        font-weight: bold;
                        margin-left: 5px;
                    }

                    i:hover {
                        color: #86744e;
                    }

                }

                .nav__list:hover {
                    color: #86744e;
                }

                .nav__list:hover i {
                    transform: rotate(180deg) !important;
                    color: #86744e;
                }

                .nav__list:hover .dropdown__container {
                    visibility: visible;
                    opacity: 1;
                    top: 80px;
                }

            }


            .dropdown__container {
                position: absolute;
                background-color: #fff;
                top: 110px;
                left: 0;
                display: flex;
                opacity: 0;
                visibility: hidden;
                transition: top 0.4s, opacity 0.2s;
                box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);

                .dropdown__group {
                    padding: 20px;
                    min-width: 350px;

                    .dropdown__title {
                        font-weight: 600;
                        letter-spacing: 1px;
                        display: flex;
                        justify-content: space-between;
                        line-height: unset;

                        span {
                            font-size: 12px;
                            text-transform: capitalize;
                            font-weight: 300;
                            margin-bottom: 20px;
                            color: #141414;
                        }
                    }

                    img {
                        width: 250px;
                        text-align: center;
                    }

                    hr {
                        margin: 6px 0px 20px 0px;
                    }

                    .dropdown__list>li {
                        display: block;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                        margin-right: 33px;
                        text-align: left;

                        a {

                            color: #141414;
                            transition: 0.4s;

                            span {
                                display: block;
                                font-size: 11px;
                                color: #646464;
                                font-weight: 300;
                                font-family: sans-serif;
                                line-height: 10px;
                            }

                        }

                        a:hover {
                            color: #86744e;
                        }
                    }
                }

                .dropdown__group:first-child {
                    background-color: #fff;

                    ul {
                        column-count: 2;
                    }
                }

                .dropdown__group:nth-child(2),
                .dropdown__group:nth-child(4) {
                    background-color: #f0f0f0;
                    min-width: 250px;
                }

                ul>li:last-child a {
                    color: #86744e;
                }
            }

        }


        .nav-right-content {

            display: flex;

            a {
                height: 100%;
                color: #30373e;

            }

            .res-search {
                display: none;
            }

            .search,
            .res-search,
            .user__account,
            .wishlist,
            .price-switcher {
                width: 40px;
                text-align: center;
            }

            .price-switcher .currency-changer {
                background-color: transparent;
                border: none !important;
                font-size: 13px !important;
                /* box-shadow: none !important; */
                /* color: #4f5d77 !important; */
                /* width: 100% !important; */
                /* border-radius: 4px !important; */
                padding: 0px 26px 0px 0px;
                line-height: 0px;
            }





            .cart {
                margin-left: 10px;
                padding-left: 20px;
                position: relative;
                font-weight: 500;

                span {
                    color: #000;
                }

                span:last-child {
                    position: absolute;
                    top: -6px;
                    right: -12px;
                    font-size: 16px;
                    color: #141414;
                }

            }

            .cart::before {
                display: block;
                content: "";
                width: 1px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 50%;
                background-color: #a9a9a9;
                transform: translateY(-50%);
            }
        }



    }

}