

.main-slider-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    overflow: visible;
    max-width: none !important;
    background: #f8f6f4;
}

// .main-slider-wrapper::after {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 60px;
//     background: rgb(249, 246, 244);
//     background: linear-gradient(180deg, rgba(249, 246, 244, 1) 0%, rgba(255, 255, 255, 1) 61%);
// }

.main-slider-svg-line {
    position: absolute;
    top: 0px;
    left: 0px;
}

.slider {
    position: relative;
    height: 140vh;
    width: 100%;
    padding-top: 100px;
}

.hero-img  {
    opacity: 1;
    position: absolute;
    left: 60%;
    right: 50%;
    top: 195px;
    transform: translate(-60%);
    width: 620px;
    display: flex;
    overflow: hidden;

    img{
        width: 620px;

        &:nth-child(1){
            transform: translateX(620px);
        }
    }
}

.slider-left-content {
    position: absolute;
    top: 55%;
    transform: translate(0px, -55%);
    display: flex;
    width: 600px;
    overflow: hidden;
    .left-content-container{
        &:nth-child(1){
            transform: translateX(600px);
        }
    }
}

.slider-left-content span {
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Jost', sans-serif;
    letter-spacing: 3px;
    color: #86744e;
    font-weight: 400;
}

.slider-left-content h4 {
    text-transform: capitalize;
    margin: 30px 0px;
    font-family: 'Prata', serif;
    color: #30373e;
    font-size: 99px;
}

.arsa-button {
    width: 160px;
    height: 50px;
    border: 2px solid #30373e;
    background: transparent;
    text-align: center;
    line-height: 40px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
}

.slider-right-content {
    display: flex;
    width: 100%;
    justify-content: end;
}

.slider-right-content .right-content-wrapper {
    text-align: center;
    width: 260px;
    height: 120vh;
    position: relative;
}

.right-content-wrapper .right-content {
    position: absolute;
    left: 25%;
    top: 28%;
    transform: translate(-35%);
}

.right-content-wrapper .right-content::before {
    content: "Jwellery";
    font-size: 113px;
    position: absolute;
    left: -45%;
    top: -41px;
    z-index: -1;
    font-family: "Prata", serif;
    color: #eaeaea;
    font-weight: 400 !important;
}


.slider-right-content .images {
    display: flex;
    width: 258px;
    overflow: hidden;
    img{
        width: 258px;
        height: 319px;
        &:nth-child(1){
            transform: translateX(258px);
        }
    }
}

.right-content-wrapper .right-content {

    font-family: "Jost", sans-serif;
    text-transform: capitalize;
}

.right-content-wrapper .right-content h5 {
    margin: 30px 0px 5px 0px;
    font-size: 18px;
    font-weight: 400;
    color: #30373e;
}

.right-content-wrapper .right-content h6 {
    font-size: 12px;
    color: #30373e;
    font-weight: 400;
}

.slider-arrow {
    position: absolute;
    right: 30px;
    bottom: 80px;
    display: flex;
}

.slider-arrow .right-arrow {
    margin-right: 10px;
}

.slider-arrow .left-arrow,
.slider-arrow .right-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    cursor: pointer;
    transition: 0.3s;
}

.slider-arrow .left-arrow:hover,
.slider-arrow .right-arrow:hover {
    background: #86744e;
    color: #fff;
}

.slider-arrow .left-arrow,
i,
.slider-arrow .right-arrow,
i {
    color: #30373e;
    font-size: 20px;

}

// .slider-arrow .left-arrow,
// i:hover,
// .slider-arrow .right-arrow,
// i:hover {
//     color: #fff;

// }

.new-collection-wrapper img {
    width: 100%;
}


// ////////  NAV LOGO ///////// //

// header {
//     position: sticky ;
//     top: 0;
//     left: 0;
//     width: 100%;
//     background-color: #f9f6f4;
//     z-index: 1;

//     nav {
//         height: 100px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         .nav__logo {
//             width: 10%;
//             margin-right: 40px;

//             img {
//                 width: 100%;
//             }
//         }

//         .nav__menu {
//             // margin-right: auto;
//             position: relative;
//             width: 45%;

//             ul {
//                 li {
//                     display: inline-flex;

//                     .nav__list {
//                         text-transform: uppercase;
//                         color: #141414;
//                         font-weight: 500;
//                         transition: all .3s;
//                         display: flex;
//                         align-items: center;
//                         padding: 0px 8px;

//                         i {
//                             font-size: 13px;
//                             transition: .3s;
//                             font-weight: bold;
//                             margin-left: 5px;
//                         }

//                         i:hover {
//                             color: #86744e;
//                         }
//                     }

//                     .nav__list:hover {
//                         color: #86744e;
//                     }

//                     .nav__list:hover i {
//                         transform: rotate(180deg) !important;
//                         color: #86744e;
//                     }

//                     .nav__list:hover .dropdown__container {
//                         visibility: visible;
//                         opacity: 1;
//                         top: 60px;



//                     }
//                 }
//             }

//             .dropdown__container {
//                 position: absolute;
//                 background-color: #fff;
//                 top: 110px;
//                 left: 0;
//                 display: flex;
//                 opacity: 0;
//                 visibility: hidden;
//                 transition: top 0.4s, opacity 0.2s;
//                 box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);

//                 .dropdown__group {
//                     padding: 20px;
//                     min-width: 250px;

//                     .dropdown__title {
//                         font-weight: 600;
//                         letter-spacing: 1px;
//                         display: flex;
//                         justify-content: space-between;

//                         span {
//                             font-size: 12px;
//                             text-transform: capitalize;
//                             font-weight: 300;
//                             margin-bottom: 20px;
//                             color: #141414;
//                         }
//                     }

//                     img {
//                         width: 250px;
//                         text-align: center;
//                     }

//                     hr {
//                         margin: 6px 0px 20px 0px;
//                     }

//                     .dropdown__list>li {
//                         display: block;
//                         text-transform: capitalize;
//                         margin-bottom: 10px;
//                         margin-right: 33px;

//                         a {

//                             color: #141414;
//                             transition: 0.4s;

//                             span {
//                                 display: block;
//                                 font-size: 11px;
//                                 color: #646464;
//                                 font-weight: 300;
//                                 font-family: sans-serif;
//                                 line-height: 10px;
//                             }

//                         }

//                         a:hover {
//                             color: #86744e;
//                         }
//                     }
//                 }

//                 .dropdown__group:first-child {
//                     background-color: #fff;

//                     ul {
//                         column-count: 2;
//                     }
//                 }

//                 .dropdown__group:nth-child(2),
//                 .dropdown__group:nth-child(4) {
//                     background-color: #f0f0f0;
//                     min-width: 250px;
//                 }

//                 ul>li:last-child a {
//                     color: #86744e;
//                 }
//             }
//         }
//     }

//     .nav__right-content {
//         display: flex;
//         width: 45%;
//         justify-content: flex-end;
//         a {
//             height: 100%;
//         }

//         .search,
//         .user__account,
//         .wishlist {
//             width: 40px;
//             text-align: center;
//         }

//         .cart {
//             margin-left: 10px;
//             padding-left: 20px;
//             position: relative;
//             font-weight: 500;

//             span {
//                 color: #000;
//             }

//             span:first-child {
//                 margin-right: 10px;
//             }

//             span:last-child {
//                 position: absolute;
//                 top: -6px;
//                 right: -12px;
//                 font-size: 16px;
//                 color: #141414;
//             }

//         }

//         .cart::before {
//             display: block;
//             content: "";
//             width: 1px;
//             height: 14px;
//             position: absolute;
//             left: 0;
//             top: 50%;
//             background-color: #e2e2e2;
//             transform: translateY(-50%);
//         }
//     }
// }


// ////////  NAV LOGO 2 ///////// //




.filer__gallery__section {

    .gallery__content {
        .tabs {
            hr {
                margin: 10px 0px 40px 0px;
            }

            span {
                position: relative;
                font-family: "Raleway", sans-serif;
                font-weight: 500;
                padding-right: 30px;
                letter-spacing: 0.5px;
                cursor: pointer;
            }

            span:hover::after {
                opacity: 1;
                width: 100%;
            }

            span::after {
                content: '';
                transition: .4s;
                position: absolute;
                left: 0px;
                top: 31px;
                width: 0px;
                height: 1px;
                background-color: #141414;
                opacity: 0;
            }
        }

        .product__image {
            position: relative;
            overflow: hidden;


            .product__media_hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity .3s ease;

                img {
                    transform: scale(1.06) translateX(0);
                    transition: transform .6s cubic-bezier(.61, 1, .88, 1);
                }
            }

            .product__action {
                width: 100%;
                height: 60px;
                background-color: #ffffffD9 !important;
                color: #fff;
                text-align: center;
                line-height: 60px;
                position: absolute;
                bottom: 0;
                opacity: 0;
                transition: all 0.5s;
                transform: translateY(100%);

                a {
                    font-size: 12px;
                    color: #000000;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 0.9px;
                    position: relative;
                }

                a::after {
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    background-color: #000;
                    width: 0px;
                    height: 1px;
                    transition: .3s;

                }

                a:hover::after {
                    width: 100%;
                }

            }

        }

        .product__image:hover .product__media_hover {
            opacity: 1;

            img {
                transform: scale(1) translateX(0);
                transition: transform .6s cubic-bezier(.61, 1, .88, 1);
            }

        }

        .products__content {
            padding: 20px 0px;

            .title__holder {
                display: flex;
                justify-content: space-between;

                .product__title {
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                .products__actions {
                    i {
                        font-size: 16px;
                    }

                    i:last-child {
                        margin-left: 18px;
                    }
                }
            }

            .product__info {
                text-transform: capitalize;
            }

            .products__price {
                font-weight: 500;
            }
        }

        .product__image:hover .product__action {
            transform: translateY(0%);
            opacity: 1;
        }



    }
}



.footer-wraper {
    border-top: 1px solid #eee;
    text-align: center;
    padding: 60px 0;

    .contact-area-wraper {

        padding: 40px 40px 40px 0px;
        height: 100%;
    }

    .social-network-area {
        padding: 40px 0px 40px 40px;
        height: 100%;

        ul>li {
            display: inline-block;
            margin: 0 15px;

            i {
                color: #86744e;
                cursor: pointer;
            }
        }
    }

    .newsletter-area {
        height: 100%;
        padding-left: 40px;
        padding-right: 40px;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;

        form {
            overflow: hidden;
            width: 80%;
            margin: 10px auto;


            input {
                width: 100%;
                float: left;
                padding: 15px 20px;
                text-align: center;
                font-family: inherit;
                font-size: 1.1rem;
                letter-spacing: 0.05rem;
                outline: 0;

                &[type=email] {
                    margin-bottom: 15px;
                    border: 1px solid lighten(#000000, 50%);
                    @include transition(all .4s);

                    @media (min-width: 768px) {
                        margin-bottom: 0px;
                        width: 75%;
                        border-right-width: 0px;
                    }

                    &:focus {
                        border-color: #000;
                    }
                }

                &[type=submit] {
                    background-color: #86744e;
                    color: $white;
                    border: 1px solid #86744e;
                    @include transition(all .4s);

                    @media (min-width: 768px) {
                        width: 25%;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: darken(#86744e, 10%);
                        border-color: darken(#86744e, 10%);
                    }
                }
            }
        }
    }

    .footer-title {
        font-size: 15px;
        color: #141414;
        font-family: Poppins, sans-serif;
        font-weight: 500;
        line-height: 1.46667em;
        text-transform: uppercase;
        letter-spacing: .015em;
        margin-bottom: 11px;
        word-wrap: break-word;
        display: block;
    }

    .newsletter-area .footer-title {
        font-size: 27px !important;
    }

    .contact-area-wraper {
        p {
            color: #141414;
        }
    }
}

.main-footer {
    background-color: #f9f6f4;
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: 0 solid #eee;

    .footer-image {
        width: 250px;
        display: block;
    }

    .payment-methods-image {
        width: 200px;
    }

    span {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: #141414;
        margin-bottom: 10px;
        display: block;
    }

}

.copy-right {
    border-top: 1px solid #d9d9d9;
    background-color: #f9f6f4;

    .copyright-area {

        display: flex;
        justify-content: space-between;
        padding: 10px 3%;
        align-items: center;

        span {
            margin: 0px;
            font-weight: 600;
        }

        img {
            width: 200px;
        }
    }
}

$blue: #293b49;
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;

.center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 150px;
    margin-bottom: 100px;
}

.error {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.number {
    font-weight: 900;
    font-size: 15rem;
    line-height: 1;
}

.illustration {
    position: relative;
    width: 12.2rem;
    margin: 0 2.1rem;
}

.circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 12.2rem;
    height: 11.4rem;
    border-radius: 50%;
    background-color: $blue;
}

.clip {
    position: absolute;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    width: 12.5rem;
    height: 13rem;
    border-radius: 0 0 50% 50%;
}

.paper {
    position: absolute;
    bottom: -0.3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 9.2rem;
    height: 12.4rem;
    border: 0.3rem solid $blue;
    background-color: $white;
    border-radius: 0.8rem;

    &::before {
        content: "";
        position: absolute;
        top: -0.7rem;
        right: -0.7rem;
        width: 1.4rem;
        height: 1rem;
        background-color: $white;
        border-bottom: 0.3rem solid $blue;
        transform: rotate(45deg);
    }
}

.face {
    position: relative;
    margin-top: 2.3rem;
}

.eyes {
    position: absolute;
    top: 0;
    left: 2.4rem;
    width: 4.6rem;
    height: 0.8rem;
}

.eye {
    position: absolute;
    bottom: 0;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: $blue;
    animation-name: eye;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &-left {
        left: 0;
    }

    &-right {
        right: 0;
    }
}

@keyframes eye {
    0% {
        height: 0.8rem;
    }

    50% {
        height: 0.8rem;
    }

    52% {
        height: 0.1rem;
    }

    54% {
        height: 0.8rem;
    }

    100% {
        height: 0.8rem;
    }
}

.rosyCheeks {
    position: absolute;
    top: 1.6rem;
    width: 1rem;
    height: 0.2rem;
    border-radius: 50%;
    background-color: $pink;

    &-left {
        left: 1.4rem;
    }

    &-right {
        right: 1.4rem;
    }
}

.mouth {
    position: absolute;
    top: 3.1rem;
    left: 50%;
    width: 1.6rem;
    height: 0.2rem;
    border-radius: 0.1rem;
    transform: translateX(-50%);
    background-color: $blue;
}

.text {
    margin-top: 5rem;
    font-weight: 300;
    color: $blue;
}

.button {
    margin-top: 4rem;
    padding: 1.2rem 3rem;
    color: $white;
    background-color: #86744e;
    transition: .3s;

    &:hover {
        background-color: #646464;
        color: #fff;
    }
}

.masonary {
    display: flex;
    width: 100vw !important;
    padding: 0 40px;
    height: 400px;
    margin: 80px 0;

    left: 0 !important;

    .gallery-item-1,
    .gallery-item-2,
    .gallery-item-3,
    .gallery-item-4 {
        padding-right: 10px;

    }

    .gallery-item-2,
    .gallery-item-4 {
        margin-top: 60px;
    }

    // left: 0!important;
    .gallery-item-1,
    .gallery-item-2,
    .gallery-item-3,
    .gallery-item-4,
    .gallery-item-5 {
        height: 400px;
        width: 400px;

        img {
            // width: 100%;
            // height: 100%;
        }
    }

}





/////////   NAVBAR ON PAGE CHANGE  ///////////
.navbar-other-page {
    background: #fafafa;
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 0px 5px 15px rgba(15, 36, 84, 0.05);
    height: 90px;
    line-height: 90px;
    z-index: 3;
    top: 0;

    nav {

        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar-left {
            width: 100px;
            position: relative;

            img {
                width: 100%;
            }
        }

        .nav-middle-content {
            width: 60%;
            text-align: center;
            position: relative;

            ul>li {
                display: inline-flex;

                .nav__list {
                    text-transform: uppercase;
                    color: #141414;
                    font-weight: 500;
                    transition: all .3s;
                    display: flex;
                    align-items: center;
                    padding: 0px 0.5rem;

                    span {
                        line-height: 5rem;

                    }

                    i {
                        font-size: 13px;
                        transition: .3s;
                        font-weight: bold;
                        margin-left: 5px;
                    }

                    i:hover {
                        color: #86744e;
                    }

                }

                .nav__list:hover {
                    color: #86744e;
                }

                .nav__list:hover i {
                    transform: rotate(180deg) !important;
                    color: #86744e;
                }

                .nav__list:hover .dropdown__container {
                    visibility: visible;
                    opacity: 1;
                    top: 80px;
                }

            }


            .dropdown__container {
                position: absolute;
                background-color: #fff;
                top: 110px;
                left: 0;
                display: flex;
                opacity: 0;
                visibility: hidden;
                transition: top 0.4s, opacity 0.2s;
                box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);

                .dropdown__group {
                    padding: 20px;
                    min-width: 350px;

                    .dropdown__title {
                        font-weight: 600;
                        letter-spacing: 1px;
                        display: flex;
                        justify-content: space-between;
                        line-height: 20px !important;

                        span {
                            font-size: 12px;
                            text-transform: capitalize;
                            font-weight: 300;
                            margin-bottom: 20px;
                            color: #141414;
                        }
                    }

                    img {
                        width: 250px;
                        text-align: center;
                    }

                    hr {
                        margin: 6px 0px 20px 0px;
                    }

                    .dropdown__list>li {
                        display: block;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                        margin-right: 33px;
                        line-height: 25px !important;
                        text-align: left;

                        a {

                            color: #141414;
                            transition: 0.4s;

                            span {
                                display: block;
                                font-size: 11px;
                                color: #646464;
                                font-weight: 300;
                                font-family: sans-serif;
                                line-height: 10px !important;
                            }

                        }

                        a:hover {
                            color: #86744e;
                        }
                    }
                }

                .dropdown__group:first-child {
                    background-color: #fff;

                    ul {
                        column-count: 2;
                    }
                }

                .dropdown__group:nth-child(2),
                .dropdown__group:nth-child(4) {
                    background-color: #f0f0f0;
                    min-width: 250px;
                }

                ul>li:last-child a {
                    color: #86744e;
                }
            }

        }
        .navbar-right{
            display: flex;
            a {
                height: 100%;
            }

            .search,
            .user__account,
            .wishlist
            {
                width: 40px;
                text-align: center;
            }

            .price-switcher .currency-changer {
                background-color: transparent !important;
                border: none !important;
                font-size: 13px !important;
            }

            .cart {
                margin-left: 10px;
                padding-left: 20px;
                position: relative;
                font-weight: 500;

                span {
                    color: #000;
                }

                span:first-child {
                    margin-right: 10px;
                }

                span:last-child {
                    position: absolute;
                    top: -6px;
                    right: -12px;
                    font-size: 16px;
                    color: #141414;
                }

            }

            .cart::before {
                display: block;
                content: "";
                width: 1px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 50%;
                background-color: #e2e2e2;
                transform: translateY(-50%);
            }
        }
    }

}
.bread-crumb{
    width: 100%;
    min-height: 90px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 90px;
    background-color: #f9f6f4;
    padding: 30px 0 ;

    .menu-name{
        span:first-child{
            font-size: 20px;
            margin-right: 10px;
            font-weight: 500;
            color: #86744e;
        }

    }
    .page-map{
        .breadcrumb{
            margin: 0px;
        }

        
    }
}

.product__grids img{
    border-radius: 15px;
    height: 100%;
}