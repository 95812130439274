.bread-crumb{
    width: 100%;
    min-height: 90px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 90px;
    background-color: #f9f6f4;
    padding: 30px 0 ;

    .menu-name{
        span:first-child{
            font-size: 20px;
            margin-right: 10px;
            font-weight: 500;
            color: #86744e;
        }

    }
    .page-map{
        .breadcrumb{
            margin: 0px;
        }

        
    }
}