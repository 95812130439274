.wishlist-wrapper {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    align-items: center;
    

    .wishlist-image {
        width: 6.25rem;
    }

    .wishlist-title {
        padding-left: 1rem;
        position: relative;
        width: 100%;

        .remove-wish-product {
            position: absolute;
            right: 0.25rem;
            top: 0.25rem;
            cursor: pointer;

            i{
                color: $bg-color-dark;
            }

        }

        h6 {
            font-weight: 400 !important;
        }

        .review-wrapper {
            display: inline-flex;
            margin: .3rem 0;

            .rivew-rating {
                margin-right: 10px;

                span {

                    color: #fff;
                    background: green;
                    padding: 4px 7px;
                    border-radius: 5px;
                    font-size: .8rem;

                    i {
                        padding-left: 5px;
                        font-size: .8rem;
                        color: #fff;
                    }
                }
            }

            .reviwed-user-count {
                span {
                    font-size: 1rem;
                }

            }
        }

        .product-price {
            margin-top: 5px;

            span {
                font-size: 1.2rem;
                font-weight: 500;

            }
        }
    }
}