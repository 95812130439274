.value-sec{
    position: relative;
    margin-bottom: 10px;
    input{
        border-radius: 0px !important;
    }
    .delete-icon{
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translate(0, -50%);
        background: #efefef;
        padding: 3px 10px;
        cursor: pointer;
    }
    
}

.value-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}