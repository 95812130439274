.price-setting-row {
    display: flex;
    border: 1px solid #e7ebed !important;
    padding: 5px;
    align-items: center;

    input:nth-of-type(2) {
        width: 200px !important;
    }

    input {
        margin-right: 5px;
        background: none !important;
        border-radius: 0px !important;
        border: 2px solid #f4f5f9 !important;
    }

    i {
        width: 50px;
        height: 35px;
        border: 2px solid #f4f5f9;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }
    i:nth-of-type(1){
        margin-right: 5px;
    }
}