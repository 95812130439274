.hero-banner-wrap {
    .hero-slider {
        position: relative;

        img {
            width: 100%;
            background-size: cover;
            background-position: center center;
        }

        .slider-content {
            position: absolute;
            top: 35%;
            left: 50%;
            transform: translate(-50%);
            text-align: center;

            img {
                max-width: 90px;
                margin: 10px auto;
            }

            h1 {
                color: $white;
                line-height: 80px;
                font-size: 70px;
            }

            span {
                width: 150px;
                height: 1px;
                background-color: $white;
                margin: 45px auto 45px;
                display: block;
            }

            p {
                color: #fff;
                font-size: 12px;
                letter-spacing: 3px;
                font-weight: 400;
                margin-bottom: 80px;
                text-transform: uppercase;
            }

            .hero-banner-btn {
                width: 230px;
                height: 90px;
                line-height: 90px;
            }
        }
    }
}