.account-header{
    background: $bg-color-light;
    margin-top: 90px;
    padding: 3rem;

    
    nav {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
   
}
.accounnt_body{
    margin-top: -80px;
    padding-bottom: 50px;
}
.accounnt_body .sidenav {
    background-color: #fff;
    box-shadow: 0 .3rem 1.525rem -.375rem rgba(0,0,0,.1)!important;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    border-right: unset;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6!important;
}
.accounnt_body .sidenav.navbar .navbar-nav {
    padding: 0;
    width: 100%;
    margin-bottom: 0;
}

.accounnt_body .avater {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
    text-align: center;
    color: $bg-color-dark;
    background-color: $bg-color-light;
}
.accounnt_body .sidenav.navbar .navbar-nav .nav-item .nav-link {
    padding: 15px 25px;
    font-weight: 600;
    background-color: transparent;
    border-radius: 0;
    color: #343a40;
    position: relative;
    transition: all .3s;
    opacity: .8;
    font-size: 14px;
}
.accounnt_body .sidenav.navbar .navbar-nav .nav-item .nav-link:after {
    content: "";
    float: right;
    margin-right: 5px;
    border: 0;
    font-size: 10px;
    margin-top: 5px;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
}

.accounnt_body .sidenav.navbar .navbar-nav .nav-item {
    border-top: 1px solid #dee2e6;
    margin-bottom: 0;
    transition: .3s ease-in-out;
}
.accounnt_body .sidenav.navbar .navbar-nav .nav-item:hover{
    background: $bg-color-light;
}

.accounnt_body .sidenav.navbar .navbar-nav .nav-item .nav-link {
    padding: 15px 25px;
    font-weight: 600;
    background-color: transparent;
    border-radius: 0;
    color: $bg-color-extra-dark;
    position: relative;
    transition: all .3s;
    opacity: .8;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.accounnt_body .sidenav.navbar .navbar-nav .nav-item .nav-link i {
    font-size: 1.125rem;
    opacity: .8;
    margin-top: -2px;
    margin-right: 10px;
    color: $bg-color-extra-dark;
}

.address-block {
    text-align: left;
    position: relative;
    margin-bottom: 20px;
}

.address-block .edit_address {
    position: absolute;
    right: 45px;
    top: 15px;
    color: #343a40;
}   
.address-block .delete_address {
    position: absolute;
    right: 15px;
    top: 15px;
}
.table .badge {
    padding: 5px 10px;
    border-radius: 50px;
}

.bg-soft-info {
    color: #17a2b8;
    background-color: rgba(23,162,184,.1);
}

.bg-soft-danger {
    color: #dc3545;
    background-color: rgba(220,53,69,.1);
}
.bg-soft-warning {
    color: #ffc107;
    background-color: rgba(255,193,7,.1);
}
.bg-soft-success {
    color: #28a745;
    background-color: rgba(40,167,69,.1);
}